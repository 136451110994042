<template>
<div class="container-fluid">
    <div class="d-none container bg-white">
        <nav class="navbar">
            <div class="container-fluid">
                <a class="navbar-brand" href="#">
                    <img src="https://conserflow.com.mx/images/conserflow.webp" alt="Logo" height="30" class="d-inline-block align-text-top img-brand">
                </a>
            </div>
        </nav>
    </div>
    <div class="bg-white1 my-4">
        <!-- <p class="text-center h4">Reglamento Interno de SSMA 2023</p> -->

        <div class="gallery-container">
            <div class="thumbnails_gallery"></div>
            <div class="scrollbar">
                <div class="thumb"></div>
            </div>
            <div class="slides">
                <div><img src="img/DI-CSF-007 Lineamientos de acceso a Conserflow_pages-to-jpg-0001.jpg"></div>
                <div><img src="img/DI-CSF-007 Lineamientos de acceso a Conserflow_pages-to-jpg-0002.jpg"></div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
// import gallery from "./assets/js/gallery";
export default
{
    name: 'App',
    methods:
    {
        load()
        {
            const slideGallery = document.querySelector('.slides');
            // console.error(slideGallery);
            const slides = slideGallery.querySelectorAll('div');
            // console.error(slides);
            const scrollbarThumb = document.querySelector('.thumb');
            // console.error(scrollbarThumb);
            const slideCount = slides.length;
            const slideHeight = 720;
            const marginTop = 16;

            const scrollThumb = () =>
            {
                const index = Math.floor(slideGallery.scrollTop / slideHeight);
                scrollbarThumb.style.height = `${ ((index + 1) / slideCount) * slideHeight }px`;
            };

            const scrollToElement = el =>
            {
                const index = parseInt(el.dataset.id, 10);
                slideGallery.scrollTo(
                {
                    left: 0,
                    top: index * slideHeight + marginTop,
                    behavior: 'smooth'
                });
            };

            document.querySelector('.thumbnails_gallery').innerHTML += [...slides]
                .map(
                    (slide, i) =>
                    {
                        let img = `<img src="${ slide.querySelector('img').src }" data-id="${ i }">`;
                        return img;
                    }
                )
                .join('');

            document.querySelectorAll('.thumbnails_gallery img').forEach(el =>
            {
                el.addEventListener('click', () => scrollToElement(el));
            });

            slideGallery.addEventListener('scroll', e => scrollThumb(e));

            scrollThumb();
            // Evitar click derecho
            document.addEventListener('contextmenu', event => event.preventDefault());

            document.addEventListener('keyup', (e) =>
            {
                if (e.key == 'PrintScreen')
                {
                    navigator.clipboard.writeText('');
                    alert('Nope');
                }
            });
        },

        preventPress()
        {
            const absorbEvent_ = (event) =>
            {
                var e = event || window.event;
                e.preventDefault && e.preventDefault();
                e.stopPropagation && e.stopPropagation();
                e.cancelBubble = true;
                e.returnValue = false;
                return false;
            }
            const nodes = document.getElementsByTagName('img');
            for (var i = 0; i < nodes.length; i++)
            {
                // nodes[i].ontouchstart = absorbEvent_;
                // nodes[i].ontouchmove = absorbEvent_;
                nodes[i].ontouchend = absorbEvent_;
                nodes[i].ontouchcancel = absorbEvent_;
            }
        }
    },
    mounted()
    {
        this.load();
        // this.preventPress();
    }
}
</script>

<style>
</style>
